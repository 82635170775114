/* eslint-disable camelcase */
import { nestedObjectToFormdata } from '@utils/common-methods/common'

interface IMixin {
  methods: object
}

interface BasePayloadForLinkAccount {
  account_name: string
  transit_number: string
  routing_number: string
  account_number: string
  is_personal_account: boolean
}

type LinkAccountFlowType = 'PLAID' | 'manual'

interface PayloadForLinkAccount {
  type: LinkAccountFlowType
  basePayload: BasePayloadForLinkAccount
  plaidAccountId?: undefined | string
  chequeFile?: null | File
}

const connectBankAccountMixin: IMixin = {
  methods: {
    handlePayloadForLinkAccount({ type, basePayload, plaidAccountId, chequeFile }: PayloadForLinkAccount): object | FormData {
      let formData
      const plaidPayload = {}

      if (type === 'PLAID') {
        Object.assign(plaidPayload, basePayload, {
          plaid_account_id: plaidAccountId,
        })
      } else { // manual flow
        formData = nestedObjectToFormdata({
          eft_account: Object.assign({}, basePayload, {
            'verification_image': chequeFile
          })
        })
      }
      // @ts-ignore
      return type === 'PLAID' ? { eft_account: plaidPayload } : formData
    }
  }
}

export default connectBankAccountMixin
