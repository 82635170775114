














import Vue from 'vue'
import Modal from '@components/modal/continue-or-cancel-modal.vue'

export default Vue.extend({
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      required: true
    },
    corporationId: {
      type: String,
      required: true
    },
  },
  methods: {
    toWalletIndexPage() {
      this.$router.replace(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet`)
    },
    toAddFundsPage() {
      this.$router.replace(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet/funds`)
    },
  },
})
