































import Vue from 'vue'
import connectBankAccountMixin from '@utils/mixins/connect-bank-account-mixin'
import Fields from '@views/wallet/accounts/FormFields.vue'
import PadAgreement from './PadAgreement.vue'
import SuccessModal from './SuccessModal.vue'
import { getBankAccountInfoFromPlaid, connectAccount } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed } from '@/types/addy_plus/corporations/wallet/ConfirmAccountDetails'

export default Vue.extend<IData, IMethods, IComputed>({
  // @ts-ignore
  mixins: [connectBankAccountMixin],
  data() {
    return {
      accountName: '',
      transitNumber: '',
      routingNumber: '',
      accountNumber: '',
      isPersonalAccount: false,
      plaidAccountId: '',
      isLinking: false,
      showSuccessModal: false,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  components: {
    Fields,
    PadAgreement,
    SuccessModal,
  },
  created() {
    this.autoPopulate()
  },
  methods: {
    autoPopulate() {
      const { token, accountId, sessionId } = this.$route.query
      getBankAccountInfoFromPlaid(this.corporationId, {
        plaid_token: decodeURIComponent(token as string),
        plaid_account_id: decodeURIComponent(accountId as string),
        plaid_link_session_id: decodeURIComponent(sessionId as string),
      }).then((data) => {
        if (!data.success) return
        const { accountName, transitNumber, routingNumber, accountNumber, plaidAccountId } = data.data.eftAccount
        this.accountName = accountName as string
        this.transitNumber = transitNumber as string
        this.routingNumber = routingNumber as string
        this.accountNumber = accountNumber as string
        this.plaidAccountId = plaidAccountId as string
      }).catch(() => {
        this.$store.commit('toggleLoadingMask', false)
        this.goBack()
      })
    },
    goBack() {
      this.$router.replace(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet/accounts/new`)
    },
    linkAccount() {
      this.isLinking = true

      const basePayload = {
        account_name: this.accountName,
        transit_number: this.transitNumber,
        routing_number: this.routingNumber,
        account_number: this.accountNumber,
        is_personal_account: false, // TODO: need to confirm this
      }

      const params = this.handlePayloadForLinkAccount({ type: 'PLAID', basePayload, plaidAccountId: this.plaidAccountId })
      connectAccount(this.corporationId, params).then((data) => {
        this.isLinking = false
        if (!data.success) return

        this.showSuccessModal = true
      })
    },
  },
})
